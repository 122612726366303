<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <label>Status</label>
                            <v-select
                              placeholder="None"
                              v-model="status"
                              label="fullname"
                              :options="['Unpaid','Release','Paid','Block']"
                            >
                            </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <label>Select Channel Partner</label>
                            <v-select
                              placeholder="None"
                              v-model="user_id"
                              label="fullname"
                              :options="CPOption"
                            >
                            <template #option="{ fullname, profile_image, username }">
                            <b-avatar :src="getprofileImage(profile_image)" />
                            <span class="font-weight-bolder"> {{ fullname}}</span><span>({{ username }})</span>
                          </template>
                            </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <label>Select Project</label>
                            <v-select
                              placeholder="None"
                              v-model="project"
                              label="projectname"
                              :options="projectOptions"
                               @input="getPlot($event)"
                            />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <label>Plot No</label>
                          <v-select placeholder="None" v-model="plot" label="plotname" :options="plotOption" />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr
                            class="form-control"
                            value=""
                            placeholder="Select Date"
                            :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                              maxDate: new Date(),
                            }"
                            @input="getdata($event)"
                            style="background-color: transparent"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="searchData"
                      >
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <report-table :data="data" :columns="fields" >
                <template #printHeader>
                    <div style="display:flex;flex-direction: column;align-items:center;width:100%;">
                      <h3 style="margin:0px">Commission Report</h3>
                    </div>
                    <div style="display:flex;justify-content:center;width:100%;flex-direction:column;align-items:center">
                      <h5 style="margin:0px" v-if="result && result.length==2">{{result[0]}} to {{result[1]}}</h5>
                    </div>
                  </template>
                </report-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios';
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import ReportTable from "../../../components/ReportTable.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import vSelect from "vue-select";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";

import moment from "moment";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BAvatar
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    vSelect,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    ReportTable,
    BAvatar
  },
  data() {
    return {
      data: [],
      user_id: "",
      startdate: "",
      CPOption: [],
      projectOptions:[],
      project:'',
      enddate: "",
      result: "",
      users: [],
      plots: [],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      status:'',
      fields: [
        {
          field: "user",
          label: "Employee/CP/Franchise",
        },
        {
          field: "project",
          label: "Project",
        },
        {
          field: "plot",
          label: "Plot",
        },
        {
          field: "sales_date",
          label: "Sales Date Time",
        },
        {
          field: "plot_amount",
          label: "Plot Amount",
        },
        {
          field: "per",
          label: "Commission %",
        },
        {
          field: "earning",
          label: "Earning Amount",
        },
        {
          field: "tds_amount",
          label: "TDS Amount (5%)",
        },
        {
          field: "admin_charges",
          label: "Admin Charges (5%)",
        },
        {
          field: "net_amount",
          label: "Net Amount",
        },
        {
          field: "status",
          label: "Status",
        },
      ],
      plotOption:[],
      plot:''
    };
  },
  mounted() {
    this.getChannelPartner();
    this.getProject();
  },
  methods: {
    async getPlot(e){
      if(!e){
        this.plotOption=[]
        return
      }
      const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/getallplotbyproject/${e.id}`,
        };
        await axios(requestOptions)
          .then((response) => {
            this.plotOption = response.data.data;
          })
          .catch((error) => console.log(error, "error"));
    },
    async getProject() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getProject`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.projectOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getChannelPartner(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getChannelpartner`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.CPOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getUsers() {
      const requestOptionsUsers = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUsers`,
      };

      await axios(requestOptionsUsers).then((response) => {
        this.users = response.data.data;
      });
    },
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result[0];
      this.enddate = this.result[1];
    },
    async searchData() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          const data = {
            startdate: this.startdate,
            enddate: this.enddate,
            user_id: this.user_id.id,
            status:this.status,
            plot:this.plot?this.plot.id:'',
            project:this.project?this.project.id:'',
          };
          await axios({
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            data: JSON.stringify(data),
            url: `${this.baseApi}/receiptcommissionReport`,
          }).then((response) => {
            this.data = response.data.data;

            this.data.map((item) => {
              item.project = item.project?item.project.projectname:''
              item.plot = item.plot?item.plot.plotno:''
              item.user = item.user?item.user.fullname:''
              item.plot_amount =item.sales?item.sales.totalpayment:''
              item.sales_date = item.sales&& item.sales.bookingdate && item.sales.bookingdate!= '0000-00-00'?moment(item.sales.bookingdate).format('DD/MM/YYYY'):''
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
